// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These 
// styles are for the entire app and not just one component. Additionally, this 
// file can hold Sass mixins, functions, and placeholder classes to be imported 
// and used throughout the application.

.dashboard-option {
  height: 80px;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  background-color: #eeeeee;
  // --toolbar-text-color : #ffffff;

 
  .title{
      height: 100%;
      position: relative;
      p {
          margin: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
      }
  }
}

ion-menu{
  --background : #000000 !important;
}

.app-menu-content{
  --background : var(--ion-menu-background-color);

  .verison-name {
    position: fixed;
    left: 0px;
    bottom: 0px;
    margin: 20px;
    color: var(--ion-menu-text-color);
  }

}

.item-content {
  background-color:#000000;
}

#menu {

  color: var(--ion-menu-text-color);
  
  .inner {
    padding: 2.75em 1.5em;
  }

  .inner > h2 {
    font-weight: 900;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.35em;
  }

  .inner > ul {
    list-style: none;
    margin: 3em 0 1em 0;
    padding: 0;
  }

  .inner > ul > li {
    padding: 0;
    border-bottom: solid 1px rgba(255, 255, 255, 0.15);
  }

  .inner > ul > li p {
      display: block;
      border: 0;
      color: inherit;
  }

  .userIcon{
    font-size: 60px !important;
  }

  .margin_top_14{
    margin-top: 14px;
}

.font_size_16{
  font-size: 16px;
}

.font_size_14{
  font-size: 14px;
}

}

ion-toolbar{
  color: var(--toolbar-text-color);
}

.app-alert{
  color: var(--ion-text-color);
}

.alert-radio-label{
  color: var(--ion-text-color) !important;
}

.offline-bar{
  padding: 8px;
  background-color: #222428;
  color : white;
}

#outsource-searchbar .searchbar-input  {
  box-shadow: none !important;
  -webkit-box-shadow : none !important;
}

.toast-custom-class {
  text-align:center;
}